// .nav-tabs {
//   border-bottom-width: 0 !important;
// }
// .nav-tabs > .nav-item > .nav-link {
//   color: $button-text-color;
//   background-color: #fff;
//   border-radius: 0.5rem;
//   border-color: $border-color !important;
//   box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.1), 0px 2px 6px -1px rgba(0, 0, 0, 0.15);
//   &:hover {
//     background-color: $button-hover-color;
//   }
//   &.active {
//     background-color: $blue-200;
//     &:hover {
//       border-color: #00a8dc;
//     }
//   }
// }
// .nav-tabs > .nav-item > .nav-link.active,
// .nav-tabs > .nav-item > .nav-link.active:hover,
// .nav-tabs > .nav-item > .nav-link.active:focus {
//   border-bottom-color: $border-color;
// }

.nav-tabs {
  // box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.1), 0px 2px 6px -1px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 2px 3px -2px rgba(0, 0, 0, 0.1), 0px 3px 3px -4px rgba(0, 0, 0, 0.15);
}
.nav-tabs > .nav-item > .nav-link {
  background-color: #fff;
  border: none;
  color: $button-text-color;
  padding: 10px 24px;
  display: flex;
  align-items: center;
  &.active,
  &:active,
  &:focus,
  &:hover {
    color: $blue-300;
    //border-bottom: 3px solid $blue-300 !important;
    font-weight: bold;
  }
  em {
    font-size: 1.1rem;
    margin-right: 0.35rem;
  }
}
.nav-tabs > .nav-item {
  position: relative;
  .tab-indicator {
    display: none;
  }
  &.active {
    .nav-link {
      color: $blue-300;
      font-weight: bold;
    }
    .tab-indicator {
      display: block;
      transform: translateX(0px);
      width: 100%;
      left: 0;
      overflow: hidden;
      transition: transform 436ms cubic-bezier(0.4, 0, 0.2, 1);
      background: $blue-300;
      border-radius: 4px 4px 0 0;
      height: 4px;
      position: absolute;
      bottom: 0;
      overflow: hidden;
      transition: transform 436ms cubic-bezier(0.4, 0, 0.2, 1);
    }
    .tab-indicator::after {
      border-radius: 4px 4px 0 0;
      height: 4px;
      width: 100%;
      content: '';
    }
  }
}
.tab-content {
  border: 0;
  margin-top: 1rem;
  padding: 10px 0;
}
