.team-data-section {
  .team-row {
    margin-bottom: 1rem;
  }
  label {
    font-size: 0.75rem;
    margin-bottom: 0.15rem;
    color: $gray-600;
  }
}
