.swal-button--confirm,
.swal-button--catch,
.swal-button:focus {
  color: $button-text-color !important;
  background-color: $blue-200 !important;
  font-weight: bold;
  border-radius: 0.5rem;
  box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.1), 0px 2px 6px -1px rgba(0, 0, 0, 0.15);
  border: 1px solid $blue-200;
}
.swal-button--confirm:focus,
.swal-button--confirm:hover,
.swal-button--catch:focus,
.swal-button--catch:hover {
  border: 1px solid #00a8dc !important;
}
.swal-icon--warning__body,
.swal-icon--warning__dot {
  background-color: $red-500;
}
.swal-icon--warning {
  border-color: $red-500;
  animation: none;
}
.swal-title {
  color: $base-color;
  font-size: 1.75rem;
}
.swal-text, .swal-content {
  color: $base-color;
  font-size: 0.875rem;
  line-height: 1.52857;
}
.swal-button--cancel {
  // background-color: #fff;
  color: $button-text-color !important;
  font-weight: bold;
  border-radius: 0.5rem;
  box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.1), 0px 2px 6px -1px rgba(0, 0, 0, 0.15);
  background-color: $btn-secondary-bg;
  border-color: $btn-secondary-border;
}