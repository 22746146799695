.alert-bar {
  background-color: $gold-500;
  padding: 1rem;
  text-align: center;
  border-bottom: 1px solid $gold-600;
  &.gray-100 {
    border-bottom: 1px solid $gray-200;
  }
  &.gray-200 {
    border-bottom: 1px solid $gray-300;
  }
}
