.btn-primary,
.btn-primary:active {
  color: $button-text-color !important;
  background-color: $blue-200 !important;
  // border-color: $button-border-color !important;
  font-weight: bold;
}
.btn-secondary {
  // background-color: $gray-100;
  font-weight: bold;
}
.btn-info {
  background-color: $blue-100;
  border-color: $blue-100;
  color: $button-text-color;
}
.btn,
.btn:focus {
  border-radius: 0.5rem;
  box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.1), 0px 2px 6px -1px rgba(0, 0, 0, 0.15);
}
.btn-text {
  border: 0;
  background: none;
  color: $hyperlink-color;
  white-space: nowrap;
  &:hover {
    color: $blue-700;
    text-decoration: underline;
  }
  &:disabled {
    color: $gray-300;
    &:hover {
      text-decoration: none;
    }
  }
}
.btn {
  &.fixed-width-md {
    width: 9rem;
  }
  &.fixed-width-xmd {
    width: 11rem;
  }
  &.fixed-width-lg {
    width: 20rem;
  }
  &.display-toggle {
    font-size: 0.75rem;
    padding: 0 0.5rem;
    line-height: 0.75rem;
  }
  &.btn-team {
    background-color: $purple-400;
    color: #000;
  }
}
.preauth {
  .btn {
    font-weight: 700;
    font-size: 1rem;
  }
}
.helper-text {
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
  em {
    font-size: 1.25rem;
    color: $blue-200;
  }
}