.notifications-container {
  .card-body {
    padding: 0;
    .notification-container {
      padding: 0.75rem 1.5rem;
      cursor: pointer;
      border-bottom: 1px solid $border-color;
      .read,
      .unread {
        width: 1rem;
        height: 1rem;
        border-radius: 0.5rem;
      }
      .unread {
        background-color: $blue-300;
      }
      .btn-secondary {
        background-color: $white;
        // visibility: hidden;
      }
      &:hover {
        background-color: $gray-200;
        box-shadow: $box-shadow;
        .btn-secondary {
          border-color: $gray-300;
          // visibility: visible;
        }
      }

      .user-avatar {
        width: 3.75rem;
        height: 3.75rem;
        border-radius: 3.75rem;
        overflow: hidden;
        img {
          padding: 0;
          border: 0;
          width: 3.75rem;
          height: auto;
        }
      }
    }
  }
}
