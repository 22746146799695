.design-system-row {
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.blue-100 {
  background-color: $blue-100;
}
.blue-200 {
  background-color: $blue-200;
}
.blue-300 {
  background-color: $blue-300;
}
.blue-400 {
  background-color: $blue-400;
}
.blue-600 {
  background-color: $blue-600;
}
.blue-700 {
  background-color: $blue-700;
  color: $white;
}
.blue-800 {
  background-color: $blue-800;
  color: $white;
}
.purple-100 {
  background-color: $purple-100;
}
.purple-200 {
  background-color: $purple-200;
}
.purple-300 {
  background-color: $purple-300;
}
.purple-400 {
  background-color: $purple-400;
}
.purple-600 {
  background-color: $purple-600;
}
.purple-800 {
  background-color: $purple-800;
  color: $white;
}
.gold-200 {
  background-color: $gold-200;
}
.gold-300 {
  background-color: $gold-300;
}
.gold-400 {
  background-color: $gold-400;
}
.gold-500 {
  background-color: $gold-500;
}
.gold-600 {
  background-color: $gold-600;
}
.gold-700 {
  background-color: $gold-700;
}
.gold-800 {
  background-color: $gold-800;
}
.green-100 {
  background-color: $green-100;
}
.green-200 {
  background-color: $green-200;
}
.green-300 {
  background-color: $green-300;
}
.green-400 {
  background-color: $green-400;
}
.green-600 {
  background-color: $green-600;
}
.green-700 {
  background-color: $green-700;
}
.green-800 {
  background-color: $green-800;
  color: $white;
}
.gray-100 {
  background-color: $gray-100;
}
.gray-200 {
  background-color: $gray-200;
}
.gray-300 {
  background-color: $gray-300;
}
.gray-400 {
  background-color: $gray-400;
}
.gray-600 {
  background-color: $gray-600;
}
.gray-700 {
  background-color: $gray-700;
  color: $white;
}
.gray-800 {
  background-color: $gray-800;
  color: $white;
}
.red-500 {
  background-color: $red-500;
}
