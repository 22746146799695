.modal-dialog {
  box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.1), 0px 2px 6px -1px rgba(0, 0, 0, 0.15);
  img {
    max-width: 100%;
  }
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 60rem;
  }
  .modal-medium {
    max-width: 40rem;
  }
  .modal-small {
    max-width: 20rem;
  }
}
.modal-centered {
  box-shadow: none;
  .modal-content {
    box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.1), 0px 2px 6px -1px rgba(0, 0, 0, 0.15);
  }
}
.modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modal-content {
  padding: 1.5rem 1.5rem;
}
.callout-container {
  img {
    width: 2.5rem;
    height: 2.5rem;
  }
  background-color: $gray-200;
  border-radius: 1rem;
  padding: 1rem;
}
.onboarding-modal {
  .modal-content {
    padding-top: 0;
  }
  .modal-header {
    padding: 0.5rem 0 1.5rem;
    margin: 0 -2.5rem;
    font-size: 0.9rem;
    border-bottom: 1px solid $border-color;
    .col-3 {
      text-align: center;
      &.active {
        font-weight: bold;
      }
    }
  }
  .content {
    padding-top: 1.5rem;
    p {
      margin-left: 10rem;
      margin-right: 10rem;
    }
  }
}
.interstitial-modal {
  font-weight: bold;
  font-size: 18px;

  // Define vars we'll be using
  $brand-success: $green-400;
  $loader-size: 7em;
  $check-height: $loader-size/2;
  $check-width: $check-height/2;
  $check-left: ($loader-size/6 + $loader-size/12);
  $check-thickness: 3px;
  $check-color: $brand-success;

  .circle-loader {
    margin-bottom: $loader-size/4;
    border:6px solid rgba(0, 0, 0, 0.2);
    border-left-color: $check-color;
    animation: loader-spin 1.2s infinite linear;
    position: relative;
    // display: inline-block;
    vertical-align: top;
    border-radius: 50%;
    width: $loader-size;
    height: $loader-size;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .green-thumb {
    // display: none;
    width: 2.5rem;

    // &.draw:after {
    //   animation-duration: 800ms;
    //   animation-timing-function: ease;
    //   animation-name: checkmark;
    //   transform: scaleX(-1) rotate(135deg);
    // }

    // &:after {
    //   opacity: 1;
    //   height: $check-height;
    //   width: $check-width;
    //   transform-origin: left top;
    //   border-right: $check-thickness solid $check-color;
    //   border-top: $check-thickness solid $check-color;
    //   content: '';
    //   left: $check-left;
    //   top: $check-height;
    //   position: absolute;
    // }
    &.hidden {
      display: none;
    }
  }

  .load-complete {
    -webkit-animation: none;
    animation: none;
    border-color: $check-color;
    transition: border 500ms ease-out;
    background-color: $green-100;
    .green-thumb {
      // display: block;
    }
  }

  @keyframes loader-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes checkmark {
    0% {
      height: 0;
      width: 0;
      opacity: 1;
    }
    20% {
      height: 0;
      width: $check-width;
      opacity: 1;
    }
    40% {
      height: $check-height;
      width: $check-width;
      opacity: 1;
    }
    100% {
      height: $check-height;
      width: $check-width;
      opacity: 1;
    }
  }
}
.helper-text-modal {
  .modal-content {
    border-top: 4px solid $blue-200;
    padding: 1.5rem;
  }
  .modal-body {
    padding: 0;
  }
  h3 {
    font-weight: bold;
  }
}