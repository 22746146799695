.activity-level {
  width: 9rem;
  height: 9rem;
  background-color: #fff;
  border-radius: 9rem;
  text-align: center;
  border: 6px solid $purple-300;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 12px;
  .number {
    font-size: 36px;
    font-weight: bold;
  }
}
.activity-level-caption {
  color: $gray-600;
  margin-top: 1rem;
}