.recipe-result {
  img {
    object-fit: cover;
    height: 100%;
    width: 5rem;
    border-radius: 0.25rem;
  }
  .label {
    font-size: 0.75rem;
    color: $gray-400;
  }
}
