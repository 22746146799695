.header-user-menu.btn-toolbar {
  button.btn-secondary {
    border: 0;
    padding: 0;
    box-shadow: none;
    display: flex;
    align-items: center;
    .user-avatar-initials,
    .user-avatar-photo {
      margin-right: 1rem;
    }
    .user-avatar-photo {
      img {
        width: 3.75rem;
        height: auto;
      }
    }
    &:active,
    &:hover,
    &:focus {
      background: none !important;
      box-shadow: none !important;
    }
    // &.dropdown-item {
    //   padding: 0.1875rem 1.25rem;
    //   &:hover {
    //     background-color: $gray-100 !important;
    //   }
    // }
  }
  .dropdown-item {
    font-size: 0.75rem;
    color: $base-color;
  }
}
.header-user-menu {
  display: flex;
  flex-direction: row;
  padding: 0;
  .user-block {
    padding: 0.75rem 0;
  }
  .user-avatar-initials,
  .user-avatar-photo {
    margin-right: 1rem;
  }
  .user-block-info {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 1rem;
    .user-block-name {
      color: $base-color;
      font-weight: bold;
    }
  }
  .user-avatar-photo {
    width: 3.75rem;
    height: 3.75rem;
    border-radius: 3.75rem;
    overflow: hidden;
    img {
      width: 3.75rem;
      height: auto;
    }
  }
}
