.pill-header-container {
  background-color: $gray-200;
  padding: 0.15rem 1rem;
  border-radius: 1rem;
  font-weight: bold;
  .dropdown-toggle {
    border: 0 !important;
  }
  .dropdown-item {
    font-size: 0.75rem;
  }
}
