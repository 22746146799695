.page-title-container {
  .team-name {
    margin-bottom: 0.5rem;
  }
  h1 {
    color: $base-color;
    border-bottom: 3px solid $purple-400;
    margin-bottom: 1.5rem;
    display: inline-block;
    padding-bottom: 0.25rem;
    font-size: 2.25rem;
    font-weight: bold;
    em {
      margin-right: 1rem;
    }
  }
}
