.fade-enter {
  opacity: 0.01;
  max-height: 0;
  overflow: hidden;
}
.fade-enter-active {
  opacity: 1;
  max-height: 5000px;
  transition: max-height 0.25s ease-in, opacity 0.25s ease-in;
}
.fade-exit {
  opacity: 1;
  max-height: 5000px;
}
.fade-exit-active {
  opacity: 0.01;
  max-height: 0;
  transition: max-height 0.25s ease-out, opacity 0.25s ease-out;
  overflow: hidden;
}
.dropdown-menu.animated {
  animation-duration: 0.2s;
}
.page-loader {
  display: none;
}
.cross-fade-leave {
  opacity: 1;
}
.cross-fade-leave.cross-fade-leave-active {
  opacity: 0;
  transition: opacity 1s ease-in;
}

.cross-fade-enter {
  opacity: 0;
}
.cross-fade-enter.cross-fade-enter-active {
  opacity: 1;
  transition: opacity 1s ease-in;
}

.cross-fade-height {
  transition: height 0.5s ease-in-out;
}
.fade-wait-leave {
  opacity: 1;
}
.fade-wait-leave.fade-wait-leave-active {
  opacity: 0;
  transition: opacity 0.4s ease-in;
}

.fade-wait-enter {
  opacity: 0;
}
.fade-wait-enter.fade-wait-enter-active {
  opacity: 1;
  /* Delay the enter animation until the leave completes */
  transition: opacity 0.4s ease-in 0.6s;
}

.fade-wait-height {
  transition: height 0.6s ease-in-out;
}

/* custom macrostax loading icon */
.ms-line-scale,
.ms-line-scale > div {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ms-line-scale {
  display: block;
  font-size: 0;
  color: #fff;
  transform: rotate(90deg);
}
.loading-screen {
  .ms-line-scale {
    color: $blue-300;
  }
  .ms-logo {
    position: absolute;
    top: 1rem;
  }
}
.ms-line-scale.la-dark {
  color: #333;
}
.ms-line-scale > div {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
}
.ms-line-scale {
  width: 40px;
  height: 56px;
  position: absolute;
  top: 3.9rem;
}
.ms-line-scale > div {
  width: 4px;
  height: 56px;
  margin: 2px;
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 0;
  -webkit-animation: line-scale 1.2s infinite ease;
  -moz-animation: line-scale 1.2s infinite ease;
  -o-animation: line-scale 1.2s infinite ease;
  animation: line-scale 1.2s infinite ease;
}
.ms-line-scale > div:nth-child(1) {
  -webkit-animation-delay: -1.2s;
  -moz-animation-delay: -1.2s;
  -o-animation-delay: -1.2s;
  animation-delay: -1.2s;
}
.ms-line-scale > div:nth-child(2) {
  -webkit-animation-delay: -1.1s;
  -moz-animation-delay: -1.1s;
  -o-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.ms-line-scale > div:nth-child(3) {
  -webkit-animation-delay: -1s;
  -moz-animation-delay: -1s;
  -o-animation-delay: -1s;
  animation-delay: -1s;
}
.ms-line-scale > div:nth-child(4) {
  -webkit-animation-delay: -0.9s;
  -moz-animation-delay: -0.9s;
  -o-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.ms-line-scale > div:nth-child(5) {
  -webkit-animation-delay: -0.8s;
  -moz-animation-delay: -0.8s;
  -o-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.ms-line-scale.la-sm {
  width: 20px;
  height: 16px;
}
.ms-line-scale.la-sm > div {
  width: 2px;
  height: 16px;
  margin: 1px;
  margin-top: 0;
  margin-bottom: 0;
}
.ms-line-scale.la-2x {
  width: 80px;
  height: 56px;
}
.ms-line-scale.la-2x > div {
  width: 8px;
  height: 56px;
  margin: 4px;
  margin-top: 0;
  margin-bottom: 0;
}
.ms-line-scale.la-3x {
  width: 120px;
  height: 96px;
}
.ms-line-scale.la-3x > div {
  width: 12px;
  height: 96px;
  margin: 6px;
  margin-top: 0;
  margin-bottom: 0;
}
/*
* Animation
*/
@-webkit-keyframes line-scale {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}
@-moz-keyframes line-scale {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
    -moz-transform: scaleY(0.4);
    transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
    transform: scaleY(1);
  }
}
@-o-keyframes line-scale {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
    -o-transform: scaleY(0.4);
    transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
    -o-transform: scaleY(1);
    transform: scaleY(1);
  }
}
@keyframes line-scale {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
    -moz-transform: scaleY(0.4);
    -o-transform: scaleY(0.4);
    transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
    -o-transform: scaleY(1);
    transform: scaleY(1);
  }
}
.animated {
  animation: fadeIn 0.7s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
