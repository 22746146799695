// note: the @font-face imports are in index.html due to some limitations
// with resolve-url-loader that I didn't feel like digging into

// NOTE: When adding icons to the icomoon font, replace the four font files
// in /fonts and copy and paste the new styles into this .scss file below
// the [class] definitions below. Otherwise you will override some custom
// styling that we've added here.

[class^='ms-icon-'],
[class*=' ms-icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-active-campaign:before {
  content: '\e900';
}
.icon-intercom:before {
  content: '\e90c';
}
.icon-face-neutral:before {
  content: '\e908';
}
.icon-face-happy:before {
  content: '\e909';
}
.icon-face-sad:before {
  content: '\e90a';
}
.icon-feedback-outline:before {
  content: '\e901';
}
.icon-feedback:before {
  content: '\e906';
}
.icon-line-chart:before {
  content: '\e905';
}
.icon-dumbbell:before {
  content: '\e904';
}
.icon-Knife_-Fork_-Restaurant_-Food:before {
  content: '\e903';
}
.icon-Browser_-Internet_-Web_-Network_-Close-1:before {
  content: '\e902';
}
.icon-settings-switches-square4:before {
  content: '\e907';
}
.icon-stars-light-sparkle1:before {
  content: '\e90b';
}
