.spinnerModal {
  .modal-content {
    background-color: transparent;
    border-width: 0;
    text-align: center;
    color: #fff;
  }
  box-shadow: none;
}
.ms-logo {
  width: 6.9rem !important;
}
