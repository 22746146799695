input,
select {
  font-size: 100%;
}
input {
  border-color: $input-border-color !important;
  // color: $gray-800;
  color: $base-color !important;
  &:focus {
    border-color: $input-border-color-focused !important;
  }
}
.large-input-form,
.alcohol-calculator {
  input {
    font-size: 1.5rem;
    border-width: 0 0 1px 0;
    border-radius: 0;
  }
  .input-icon-container {
    input {
      padding-left: 1.2em;
    }
    em {
      position: absolute;
      top: 0.7rem;
    }
  }
}
.alcohol-calculator {
  input {
    border-width: 1px;
    padding: 2rem 1rem;
    width: 10rem;
  }
  .rc-slider,
  .slider-container {
    max-width: 20rem;
  }
  .default-container {
    max-width: 20rem;
  }
}
.inline-form {
  label {
    font-size: 0.9rem;
  }
  .stacked > label {
    font-size: 0.75rem;
    margin-bottom: 0.25rem;
  }
}
.form-control {
  padding-left: 0.65rem;
}
.required-field {
  color: red;
}
.checkbox-row {
  .form-group,
  .col-form-label {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  button.card-tool {
    border: 0;
    background: none;
  }
}
.c-checkbox input[type='checkbox']:checked + span,
.c-checkbox input[type='radio']:checked + span,
.c-radio input[type='checkbox']:checked + span,
.c-radio input[type='radio']:checked + span {
  background-color: $blue-100;
  border-color: $blue-100;
}
.c-checkbox input[type='checkbox']:checked + span:before,
.c-checkbox input[type='radio']:checked + span:before,
.c-radio input[type='checkbox']:checked + span:before,
.c-radio input[type='radio']:checked + span:before {
  color: $button-text-color;
}
.upload-field {
  button,
  button:active,
  button:hover,
  button:focus {
    border: 0;
    box-shadow: none !important;
    background-color: transparent !important;
    padding: 0;
  }
  .user-avatar-photo {
    width: 6.75rem;
    height: 6.75rem;
    border-radius: 6.75rem;
    overflow: hidden;
    img {
      // width: 4.75rem;
      width: 6.75rem;
      height: auto;
    }
  }
  .user-avatar-initials {
    height: 6.75rem;
    width: 6.75rem;
    border-radius: 4rem;
    line-height: 6.75rem;
    font-size: 2rem;
  }
  .team-logo-upload {
    height: 6.75rem;
  }
}
.stripe-element-container {
  // border: 1px solid $border-color;
  // padding: 0.75rem 0.5rem;
  &:focus {
    border-color: $input-border-color-focused !important;
  }
  height: 2.9rem;
}
.optional-field-connector {
  width: 1rem;
  height: 2.6rem;
  border-bottom: 1px solid $base-color;
  border-left: 1px solid $base-color;
  margin-left: 1rem;
  margin-right: 0.5rem;
}
.input-caption {
  font-size: 0.65rem;
  margin-top: 0.35rem;
  color: $gray-400;
}
.form-group {
  .form-group {
    margin-bottom: 0;
    flex: 1;
  }
}
.range-container {
  .caption {
    font-size: 0.75rem;
    width: 3rem;
    text-align: center;
  }
  align-items: center;
  justify-content: space-between;
}
.macro-targets-form,
.weigh-ins-form {
  .form-group {
    margin-bottom: 0;
    input {
      text-align: center;
    }
  }
  .macro-value {
    div {
      line-height: 21px;
    }
  }
}
.weigh-ins-form {
  margin: 0;
  padding: 0;
  .datetime-container {
    input {
      text-align: left;
    }
  }
}
.emoji-wrapper em {
  font-size: 4rem;
  &.checked {
    color: $blue-300;
  }
  &.unchecked {
    color: $gray-400;
  }
}
.radio-label-container {
  text-align: center;
}
.c-radio label {
  width: 100%;
}
.toggle-button {
  border: 3px solid #fff;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  font-size: 0.85rem;
  box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.1), 0px 2px 6px -1px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  padding: 1rem;
  &.checked {
    border-color: $blue-300;
    border-width: 3px;
  }
  h4 {
    margin-bottom: 0;
  }
}
label.full-size {
  font-size: 0.9rem !important;
}
.day-type-container {
  div {
    align-items: center;
    &.caption {
      line-height: 18px;
    }
  }
}
.rb-list-wrapper {
  em {
    margin-right: 0.5rem;
    font-size: 1.25rem;

    &.checked::before {
      // content: "\f058";
      color: $blue-400;
    }
  }
}
.label-left-container {
  .radio-label-container {
    text-align: left;
  }
}
.post-input-caption {
  width: 2rem;
  text-align: center;
}
input.error {
  border-color: red !important;
}
.radio-pill {
  background-color: #fff;
  width: 96px;
  height: 28px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  padding: 8px;
  font-size: 0.85rem;
  box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.1), 0px 2px 6px -1px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  border: 2px solid #fff;
  &.checked {
    border: 2px solid $blue-200;
    color: $blue-600;
    em {
      color: $blue-200;
    }
  }
  em {
    margin-right: 4px;
    color: $gray-300;
  }
}
.radio-pill-description {
  margin: 8px 0 24px 40px;
}
.StripeElement--focus {
  border-color: $gray-700;
}
.coupon-container {
  position: relative;
  .check {
    position: absolute;
    right: 12px;
    top: 40px;
  }
  .valid-coupon {
    border: 2px solid $green-400 !important;
    background-color: #fff;
  }
}
.bubble {
  position: relative;
  background: #fff;
  border: 1px solid $gray-200;
  width: 330px;
  height: 60px;
  border-radius: 10px;
  padding: 8px;
  color: $gray-700;
}
.bubble:after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-color: #fff transparent;
  border-width: 0 14px 14px;
  top: -14px;
  left: 42px;
  margin-left: -20px;
}
.react-datepicker-wrapper {
  .form-control[readonly] {
    background-color: #fff;
  }
}
.position-relative {
  position: relative;
}
input::-webkit-date-and-time-value{ text-align:left; }