.subscription-container {
  border: 3px solid #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.1), 0px 2px 6px -1px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  padding: 1.5rem 1rem;
  margin: 0 0 1.2rem 0;
  cursor: pointer;
  position: relative;
  h3 {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 0.25rem;
  }
  .interval {
    font-size: 1rem;
  }
  .tagline {
    font-size: 0.75rem;
  }
  .secondaryCallout {
    color: $green-600;
    font-weight: bold;
    text-decoration: underline;
  }
  &.selected {
    // background: $blue-700;
    // color: #fff;
    border-color: $blue-300;
  }
  &.featured {
    // border: 6px solid #FFBD00;
    // margin-top: 0.667rem;
    .card-caption {
      color: #fff;
      opacity: 0.7;
    }
    .border-bottom {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
    }
    .featured-callout {
      background-color: #FFBD00;
      width: 110px;
      text-align: center;
      color: #002130;
      font-size: 0.75rem;
      border-radius: 0.25rem;
      position: absolute;
      left: 5%;
      // margin-left: -72px;
      top: -13px;
      height: 22px;
      line-height: 22px;
      font-weight: 600;
    }
  }
  .description-container {
    height: 3rem;
  }
  .price {
    font-size: 1.25rem;
    font-weight: bold;
  }
}
.features-list {
  list-style: none;
}
.cta-container {
  font-size: 1.3125rem;
}
