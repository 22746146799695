.content-wrapper {
  border-top: none;
}
.preauth {
  .signup-form-wrapper {
    width: 90%;
    .max {
      height: 240px;
    }
  }
  .plan-selection-header {
    img {
      margin-right: 330px;
      margin-bottom: 10px;
    }

    .btn-group-toggle {
      width: 100%;
      height: 2.5rem;
      background-color: #fff;
      border-radius: 8px;
      // margin-bottom: 0.4rem;
      .btn {
        font-size: 0.875rem;
        line-height: 1.25rem;
        background-color: #fff;
        // border-radius: 300px;
        border: 4px solid #fff;
        color: $gray-600;
        box-shadow: none;
        border-radius: 8px;
        &.active {
          background-color: $blue-400;
          border: 4px solid #fff;
          box-sizing: border-box;
          color: #fff;
          transition: all 0.2s ease-out;
          box-shadow: none;
        }
      }
    }
  }
  .signup-callout-container {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    // max-width: 32rem;
    max-width: 90%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 2.5rem;
    .signup-callout-image {
      width: 100%;
      img {
        width: 100%;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        border-radius: 12px;
      }
    }
  }
  .signup-steps {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    .signup-step {
      margin-top: 1.5rem;
      font-size: 1.375rem;
      font-weight: 600;
      line-height: 1.5rem;
      &.benefit {
        font-size: 1.25rem;
        line-height: 1.25rem;
        margin-top: 1rem;
      }
      .signup-step-number {
        font-size: 0.875rem;
        width: 2rem;
        height: 2rem;
        min-width: 2rem;
        max-height: 2rem;
        background-color: $green-300;
        border-radius: 1rem;
        text-align: center;
        line-height: 2rem;
      }
      .signup-step-check em {
        color: $green-400;
      }
      .signup-step-thumb {
        border: 4px solid $green-400;
        width: 2.5rem !important;
        height: 2.5rem !important;
        border-radius: 50%;
        background-color: $green-100;
        display: flex;
        justify-content: center;
        align-items: center;

        .inner {
          box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
          width: 100%;
          height: 100%;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          .green-thumb {
            width: 1rem;
            margin-top: -2px;
          }
        }
      }
    }
  }
}
.signup-complete-container {
  font-size: 1rem;
  .row {
    margin: 0.75rem 0;
    .col {
      padding: 0;
    }
    &.border-bottom {
      margin-bottom: 1rem;
      padding-bottom: 1rem;
    }
  }
  .qr-code {
    width: 60%;
  }
}
.pricing-value-container {
  max-width: 380px;
  h2 {
    font-size: 1.25rem;
  }
  img.screenshot {
    max-width: 380px;
    margin-left: 2rem;
  }
}
@media (min-width: 48rem) {
  .section-container {
    margin-top: -2.5rem;
    background-color: transparent !important;
    z-index: 1050 !important; // note: need to set this exactly to appear on top of header
    .content-wrapper {
      padding-top: 0 !important;
    }
  }
  .pre-auth {
    .section-container {
      margin-top: 0;
    }
  }
}
@media (max-width: 47.99rem) {
  .preauth {
    .topnavbar {
      .navbar-brand {
        top: 8px;
        left: auto;
      }
      .signup-progress-bar-container {
        margin-top: 1.5rem;
      }
    }
    .signup-form-wrapper {
      width: 100%;
    }
    .section-container {
      background-color: transparent !important;
    }
    .plan-selection-header {
      img {
        margin-right: 280px;
        margin-bottom: 10px;
      }
    }
  }
}
.app-store-badge {
  max-width: 160px;
}
