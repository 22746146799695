@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600|Open+Sans:400,400i,600&display=swap');

body {
  color: $base-color;
  font-family: Montserrat, 'Open Sans';
  // font-size: 1rem;
  font-size: 0.875rem;
}
a,
.span-link {
  color: $hyperlink-color;
}
a:hover,
.span-link:hover {
  color: $blue-700;
  cursor: pointer;
  text-decoration: underline;
}
a.base {
  color: $base-color;
}
h1 {
  font-size: 1.75rem;
}
h1.page-header {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}
.page-header {
  border-bottom: none;
}
.legal {
  font-size: 0.75rem;
  line-height: 1.1rem;
}
.no-data-label {
  color: $gray-400;
  font-size: 0.75rem;
}
ul.breathing-room {
  padding-inline-start: 1rem;
  li {
    margin: 1rem 0;
  }
}
.cursor-pointer {
  cursor: pointer;
}
.copyable::after {
  content: '\f0c5';
  font-family: 'Font Awesome 5 free';
  margin-left: 0.5rem;
}
.cancelable::after {
  content: '\f057';
  font-family: 'Font Awesome 5 free';
  margin-left: 0.5rem;
}
.editable::after {
  content: '\e05f';
  font-family: 'simple-line-icons';
  margin-left: 0.5rem;
}
.linechart::after {
  content: '\e905';
  font-family: 'icomoon';
  margin-left: 0.5rem;
}
.strong {
  font-weight: bold;
}
.alert,
.caption {
  font-size: 0.75rem;
}
.alert-warning {
  background-color: $warning-color;
  border-color: $warning-color;
  color: $base-color;
  padding: 0.25rem 0.5rem;
  border-radius: 30px;
  text-align: center;
}
.icon-md {
  font-size: 1.15rem;
}
.preauth {
  h1 {
    font-size: 1.5rem;
  }
  h3 {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5rem
  }
  .fake-h3 {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .underlined {
    border-bottom: 3px solid $blue-200;
  }
  .inline-form .stacked > label {
    // font-size: 1rem;
    font-size: 0.9rem;
  }
}
.transparent {
  opacity: 0;
}
.red {
  color: $red-500;
}