.card {
  border-radius: 0.5rem;
  background-color: $gray-100;
  border: 1px solid rgb(230, 230, 230);
  box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.1), 0px 2px 6px -1px rgba(0, 0, 0, 0.15);
  .card-header {
    background: none;
  }
  .btn-group {
    .btn {
      background-color: inherit;
      box-shadow: none;
      font-size: 0.75rem;
      &.active {
        background-color: $blue-200;
        box-shadow: none;
      }
      &:focus {
        box-shadow: none !important;
      }
    }
  }
  &.transparent {
    background-color: transparent !important;
    border: 0;
    box-shadow: none;
    padding: 0;
    margin: -1px 0 0;
    .card-body {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
.card-caption {
  font-size: 0.75rem;
  color: $gray-400;
  &.span-link {
    color: $hyperlink-color !important;
  }
}
.statistic-card,
.green-thumbs-card {
  .card-header {
    text-transform: uppercase;
    color: $gray-800;
    font-weight: bold;
    .dropdown-toggle {
      border: 0 !important;
    }
    .dropdown-item {
      font-size: 0.75rem;
    }
  }
  .card-body {
    padding-top: 0;
  }
}
.statistic {
  font-size: 2.25rem;
}
.statistic-2 {
  font-size: 1.75rem;
}
.green-thumbs-card {
  .card-body {
    font-size: 0.75rem;
  }
}
.card-large {
  .card-header {
    font-size: 1.5rem;
    border-bottom: 1px solid $border-color;
  }
}
.green-thumb-column {
  padding: 0.5rem 1rem 1.5rem 1rem;
  text-align: center;
  .green-thumb-container {
    background-color: #fff;
    width: 4rem;
    height: 4rem;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 2rem;
      height: 2rem;
    }
  }
}
.subtitle {
  color: $gray-800;
  font-weight: bold;
}
.card-footer {
  background-color: inherit;
}
.card-tool,
.card-default .card-header > .card-tool {
  color: $gray-400;
  border: none;
  background: transparent;
  font-size: 0.8rem;
  padding: 0;
}
.card-body-section {
  border-top: 1px solid $border-color;
}

@media (max-width: 47.99rem) {
  .nutrition-stats-card {
    .card-header {
      flex-direction: column;
    }
  }
}
@media (min-width: 36rem) {
  .card-deck {
    .card {
      margin-right: 0rem;
    }
  }
}
.user-card {
  // background-color: $white;
  .user-avatar {
    width: 3.75rem;
    height: 3.75rem;
    border-radius: 3.75rem;
    overflow: hidden;
    img {
      padding: 0;
      border: 0;
      width: 3.75rem;
      height: auto;
    }
  }
  .card-menu {
    position: absolute;
    top: 0.5rem;
    right: 0;
    button {
      border: 0 !important;
    }
    .dropdown-item {
      font-size: 0.75rem;
      color: $base-color;
    }
  }
  &.pending {
    // opacity: 0.5;
    .user-avatar,
    .card-body {
      opacity: 0.5;
    }
  }
  .card-body {
    padding-top: 0;
    .user-name {
      font-size: 0.8rem;
      font-weight: bold;
    }
  }
}

a.user-card-link > *,
a.user-card-link:hover {
  color: $base-color;
  text-decoration: none;
}

.line-chart-card {
  .card-header {
    font-size: 1.25rem;
  }
  .card-body {
    max-width: 99%;
    margin: 0;
  }
  .card-footer {
    border-top: 0;
    font-size: 0.75rem;
  }
  .legend-color {
    width: 1.5rem;
    height: 2px;
  }
  .legend-value {
    font-size: 0.75rem;
  }
}

.calendar-card {
  .card-body {
    padding: 0;
    margin: 0;
  }
}
.large-header-card {
  .card-header {
    font-size: 1.25rem;
  }
}

.header-normal-text {
  font-size: 0.8rem;
  .alert {
    // padding: 0.5rem 1rem;
  }
}
.bblr-0 {
  border-bottom-left-radius: 0;
}
.bbrr-0 {
  border-bottom-right-radius: 0;
}
.worksheet-container {
  // margin-top: -1px;
  z-index: 10;
  position: relative;
}
.worksheetTab {
  font-size: 0.8rem;
  padding: 0.45rem 0;
  height: 80%;
  display: flex;
  justify-content: center;
  // border: 1px solid #e6e6e6;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  background-color: $gray-200;
  cursor: pointer;
  &.border-left-card {
    border-left: 1px solid #e6e6e6 !important;
    // padding-right: 1px;
  }
  &.active {
    background-color: $gray-100;
    font-weight: bold;
    // margin-top: -1px;
    color: $blue-300;
    border-bottom: 3px solid $blue-300;
    padding: 0.5rem 0;
    height: 100%;
  }
}
.card-container {
  // background-color: $white;
  // background-color: transparent;
  background-color: $gray-100;
  box-shadow: none;
  position: relative;
  border-top: 2px solid $purple-200;
}
.card-clickable {
  border-bottom: 2px solid $blue-300;
  background-color: #f5f8fa;
  &:hover {
    background-color: #ebeef0;
    box-shadow: $box-shadow;
  }
}
.card-dropdown button {
  border: 0;
  font-size: 0.75rem;
  &.dropdown-toggle {
    font-size: 0.875rem !important;
  }
}
.faq-container {
  max-width: 560px;
  margin: 0 auto;
  .card-faq {
    max-width: 560px;
    min-height: 80px;
    flex-basis: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    position: relative;
    cursor: pointer;
    em {
      position: absolute;
      right: 8px;
      top: 30px;
      transition: all 0.2s ease-out;
    }
    .card-body {
      font-size: 1rem;
    }
  }
}
