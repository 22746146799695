.footer-container {
  padding: 0.75rem 1.25rem;
  height: auto;
  min-height: 3.75rem;
  font-size: 0.75rem;
  line-height: 0.875rem;
  border-top: 0 !important;
  text-align: right;
  max-width: 80rem;
  margin: 0 auto;
  span {
    opacity: 0.8;
    font-weight: 300;
  }
  .max-height {
    height: 100%;
  }
}
