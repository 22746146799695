.slider {
  .rc-slider-track {
    background-color: $blue-200;
  }
  .rc-slider-dot-active,
  .rc-slider-handle {
    border-color: $blue-200;
  }
  .rc-slider-handle {
    height: 24px;
    width: 24px;
    margin-top: -10px;
  }
  .rc-slider-mark-text {
    font-size: 1rem;
  }
  .rc-slider-mark-text-active {
    font-weight: bold;
  }
}
