.testimonial-container {
  background: #ffffff;
  border: 5px solid $purple-400;
  border-radius: 12px;
  max-width: 90%;
  width: 90%;
  display: flex;
  flex-direction: column;
  padding: 0;
  .testimonial-quote-container {
    padding: 1.25rem;
    h2 {
      color: $purple-400;
      font-size: 1.5rem;
    }
    p {
      line-height: 1.6rem;
      margin-bottom: 0;
    }
  }
  .testimonial-photo-container {
    width: 100%;
    // height: 333px;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    border-radius: 0 0 6px 6px;
    img {
      width: 100%;
      border-radius: 0 0 6px 6px;
    }
  }
}
@media (max-width: 47.99rem) {
  .testimonial-container {
    width: 100%;
    max-width: 100%;
  }
}
