.user-avatar-initials {
  background-color: $base-color;
  color: #fff;
  text-align: center;
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 1.875rem;
  line-height: 3.75rem;
  font-weight: bold;
  font-size: 1rem;
}
.user-avatar {
  position: relative;
  img {
    border: 0;
    background: none;
    padding: 0 !important;
  }
  .badge {
    position: absolute;
    // bottom: 0;
    right: 8px;
    top: 0;
    height: 20px;
    line-height: 14px;
    text-align: center;
  }
}
.MuiTableCell-body {
  .user-avatar {
    width: 3.75rem !important;
    min-width: 3.75rem !important;
    height: 3.75rem !important;
    border-radius: 3.75rem;
    overflow: hidden;
    img {
      width: 100% !important;
      height: auto !important;
    }
  }
}
