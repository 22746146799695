.DayPicker,
.DayPickerNavigation_button,
.CalendarMonth,
.CalendarMonthGrid,
.CalendarMonthGrid_month__horizontal,
.CalendarDay {
  background-color: $gray-100 !important;
}
.DayPicker_1 {
  // border-radius: 0.5rem !important;
  // border: 1px solid rgb(230, 230, 230);
  // box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.1), 0px 2px 6px -1px rgba(0, 0, 0, 0.15);
}
.DayPicker__withBorder {
  border: 0 !important;
  box-shadow: none !important;
}
.CalendarMonth_caption {
  font-size: 1rem !important;
}
.CalendarDay__default:hover,
.DayPickerNavigation_button:hover {
  background-color: $gray-200 !important;
}
.thumb {
  &.greenthumb,
  &.graythumb,
  &.underthumb,
  &.overthumb {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 0.5rem;
  }
  &.thumb {
    margin-right: 2px;
  }
  &.greenthumb {
    background-color: #61ce99;
  }
  &.overthumb {
    background-color: #8180cd;
  }
  &.underthumb {
    background-color: #ffc400;
  }
  &.graythumb {
    background-color: #c4c4c4;
  }
}
.legend {
  width: 300px;
}
