.table-header {
  text-transform: uppercase;
  font-weight: bold;
}
.table-statistics {
  .statistic {
    font-size: 1.75rem;
  }
  .col-md-3 {
    margin-bottom: 1rem;
  }
}
.MuiPaper-root {
  border-radius: 0.5rem;
  background-color: $gray-100;
  border: 1px solid rgb(230, 230, 230);
  border-top: 2px solid $purple-200;
  // box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.1), 0px 2px 6px -1px rgba(0, 0, 0, 0.15);
  box-shadow: none !important;
}
.MuiTypography-root,
.MuiTableCell-root {
  font-family: 'Montserrat', 'Open-Sans' !important;
}
.MuiTableRow-root.MuiTableRow-hover:hover {
  box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.1), 0px 2px 6px -1px rgba(0, 0, 0, 0.15);
}
.MuiIcon-fontSizeSmall {
  font-size: 0.75rem !important;
}
.MuiInputBase-root {
  font-size: 0.85rem !important;
}
.MuiTableCell-root {
  border-color: $border-color !important;
}
.MuiTableCell-head {
  font-weight: bold !important;
  background-color: $gray-100 !important;
}
.MuiTableCell-footer {
  border-bottom: 0 !important;
}
.MuiTableRow-root:empty {
  display: none;
}
.MuiInput-underline:after {
  border-color: $input-border-color-focused !important;
}
.MuiInputBase-input, .MuiMenuItem-root {
  font-family: 'Montserrat' !important;
}