// Note: these are mostly obsolete with Bootstrap 4 equivalents now available
// https://getbootstrap.com/docs/4.0/utilities/flex/
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-row-end {
  flex-direction: row;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.flex-row.centered {
  align-items: center;
}
.justify-space-between {
  justify-content: space-between;
}
.flex-direction-column {
  flex-direction: column;
}
.flex-direction-row {
  flex-direction: row;
}
.align-items-center {
  align-items: center;
}
.justify-content-center {
  justify-content: center;
}
