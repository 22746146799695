$primary: #03b7e5;
$secondary: #fff;
$success: #27c24c;
$info: #03b7e5;
$warning: #ff902b;
$danger: #f05050;
$dark: #3a3f51;
$team-primary: #8180cd;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

$box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.3), 0px 2px 6px -1px rgba(0, 0, 0, 0.15);
