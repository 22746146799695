.user-row {
  .user-avatar {
    .user-avatar-photo,
    .user-avatar-initials {
      width: 3.75rem;
      height: 3.75rem;
      border-radius: 3.75rem;
      overflow: hidden;
      margin-right: 1rem;
      img {
        width: 3.75rem;
      }
    }
    display: flex;
    align-items: center;
  }
}
