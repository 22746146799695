// html {
//   overflow: scroll;
//   overflow-x: hidden;
//   -ms-overflow-style: none;
//   scrollbar-width: none;
// }
// ::-webkit-scrollbar {
//   width: 0;
//   background: transparent;
// }
.wrapper .aside-container {
  // bottom: auto;
  flex-basis: 13.75rem;
  position: relative;
}
.wrapper .aside-container .aside-inner {
  // padding-top: 7.5rem;
  padding-top: 2rem;
}
.sidebar:after {
  background: none;
}
.sidebar-nav > li > a,
.sidebar-nav > li > button {
  // padding: 1.25rem 1.5rem;
  padding: 12px 24px;
  display: flex;
  white-space: inherit;
}
.sidebar-subnav > li > a {
  padding: 0.5rem 1.5rem;
  font-size: 0.75rem;
}
.sidebar-nav > li.active,
.sidebar-nav > li.active > a,
.sidebar-nav > li.active > .nav-item,
.sidebar-nav > li.active .sidebar-nav,
.sidebar-nav > li.open,
.sidebar-nav > li.open > a,
.sidebar-nav > li.open > .nav-item,
.sidebar-nav > li.open .sidebar-nav {
  background: none;
}
.sidebar-nav > li.active,
.sidebar-nav > li.active > a,
.sidebar-nav > li.active > .nav-item {
  font-weight: bold;
}
.sidebar {
  .btn {
    width: 90%;
    margin-bottom: 1.125rem;
  }
}
.sidebar-nav > li > a > em,
.sidebar-nav > li > button > em,
.sidebar-nav > li > .nav-item > em,
.sidebar-nav > li > .MuiSvgIcon-root {
  font-size: 1.25rem;
  min-width: 2.25rem;
  text-align: left;
}
.sidebar-nav > li > a,
.sidebar-nav > li > button {
  color: $base-color;
  &:hover {
    text-decoration: none;
    color: $blue-300;
  }
}
@media (max-width: 47.99rem) {
  .wrapper .aside-container {
    position: absolute;
    .aside-inner {
      padding-top: 7.5rem;
    }
  }
}
