.dropdown-sub-menu {
  button.dropdown-item {
    display: flex;
    font-size: 0.75rem;
    padding: 0.25rem 1.25rem;
    &:active {
      color: $base-color;
    }
  }
  .btn {
    white-space: nowrap;
  }
  .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group > .btn-group:not(:last-child) > .btn {
    border-radius: 0.5rem;
  }
}
.dropdown-sub-menu-icon-container {
  width: 2rem;
  text-align: center;
  .icon-active-campaign,
  .icon-intercom {
    font-size: 1.25rem;
    line-height: 0.9rem;
  }
}
