.pill {
  display: inline-block;
  padding: 2px 4px;
  border-radius: 6px;
  &.warning {
    background-color: $gray-200;
  }
  &.active {
    // background-color: #61ce99;
    background-color: $green-300;
  }
}
