.user-detail-container {
  .user-avatar, .user-avatar-initials {
    border-radius: 5.625rem;
    height: 5.625rem;
    min-width: 5.625rem;
    overflow: hidden;
    width: 5.625rem;
    img {
      width: 5.625rem;
      height: auto;
    }
  }
  .user-avatar-initials {
      line-height: 5.625rem;
      font-size: 1.75rem;
  }
  .user-name {
    font-size: 1.75rem;
  }
  .user-data {
    font-size: 0.9rem;
    .user-data-section {
      margin-top: 2rem;
    }
    .user-row {
      margin-bottom: 0.5rem;
    }
    .key-value {
      display: flex;
      justify-content: space-between;
    }
  }
}
