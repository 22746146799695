$border-color: #dcdcdc;
$input-border-color: #e9e7e7;
$input-border-color-focused: #3f3f3f;
$calm-color: #03b7e5;
$assertive-color: #ff5a5f;
$assertive-hover-color: #ff343a;
$hyperlink-color: #007fae;
$base-color: #19191b;
$warning-color: #ffb400;
$info: #03b7e5;
$button-text-color: #002130;
$button-border-color: #eaeaea;
$button-hover-color: #f5f5f5;

/* from Guiceworks design system */
$blue-100: #ace3ff;
$blue-200: #10c9ff;
$blue-300: #03b7e5;
$blue-400: #009acb;
$blue-600: #007fae;
$blue-700: #005775;
$blue-800: #002130;

$purple-100: #eeeef8;
$purple-200: #bcbce3;
$purple-300: #9a9ad4;
$purple-400: #8180cd;
$purple-600: #7272b6;
$purple-800: #2b2b51;
$purple-900: #2a2962;

$gold-200: #e6d4ad;
$gold-300: #e3c180;
$gold-400: #e1b241;
$gold-600: #dea503;
$gold-700: #c58203;
$gold-800: #886602;

$green-100: #d7fff1;
$green-200: #b4fddf;
$green-300: #91ffda;
$green-400: #61ce99;
$green-600: #429b75;
$green-700: #3f8164;
$green-800: #206547;

$gray-100: #f7f7f7;
$gray-200: #e6e6e6;
$gray-300: #c4c4c4;
$gray-400: #7f7e7b;
$gray-600: #676767;
$gray-700: #3e3937;
$gray-800: #282625;

$white: #fff;

$red-500: #ff3047;
$gold-500: #ffc400;
