html {
  scroll-behavior: smooth;
}
#app {
  max-width: 80rem;
  margin: 0 auto;
}
body.preauth {
  background-color: $gray-100;
  #app {
    max-width: 100%;
    .background-container {
      background-color: $gray-100;
      // height: 40rem;
      height: 100%;
      overflow: visible;
      .background-wave {
        position: absolute;
        left: 0;
        top: 40rem;
        width: 100%;
        z-index: -1;
        img {
          width: 100%;
        }
      }
    }
    .wrapper {
      max-width: 80rem;
      margin: 0 auto;
      min-height: calc(100% - 3.75rem) !important;
    }
  }
}
.relative {
  position: relative;
}
.max-height {
  max-height: 100%;
}
.border-top {
  border-top: 1px solid $border-color;
}
.border-bottom {
  border-bottom: 1px solid $border-color;
}
.width-100-percent {
  width: 100%;
}
.display-none {
  display: none;
}
body,
.wrapper .section-container,
.wrapper .aside-container,
.sidebar,
.sidebar-subnav,
.header-user-menu button.btn-secondary {
  // background-color: #fff;
  background-color: $purple-100;
  // background-color: $gray-100 !important;
}
.section-container {
  z-index: 0 !important;
  margin-left: 0 !important;
  flex-basis: 66.25rem;
}
.w-65 {
  width: 65% !important;
}
.w-75 {
  width: 75% !important;
}
.pre-auth.wrapper {
  .section-container {
    margin-top: 2rem;
  }
  .mb-5-md {
    margin-bottom: 3rem !important;
  }
}
@media (max-width: 47.99rem) {
  .wrapper .aside-container {
    left: 0;
    top: auto;
    bottom: auto;
    .aside-inner {
      padding-top: 1rem;
    }
  }
  .pre-auth.wrapper {
    .section-container {
      margin-top: 0;
    }
    .mb-5-md {
      margin-bottom: 1.5rem !important;
    }
    .second-column {
      margin-top: 3rem;
    }
  }
}
body.preauth {
  border-top: 6px solid $blue-200;
}
.main-content-container {
  background-color: #fff;
  border: 1px solid $border-color;
  border-radius: 12px;
  padding: 1.5rem 2rem;
}
